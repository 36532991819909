// Px to rem

$html-font-size: 16px;

@function rem($size) {
    @return $size / $html-font-size * 1rem;
}

// SVG

@mixin svgIcon($name, $width, $height: $width) {
    display: inline-block;
    width: $width;
    height: $height;
    line-height: 1;
    background: transparent url("#{$img-path}#{$name}.svg") no-repeat center;
}

// Grayscale

@mixin grayscale($value) {
    filter: #{"grayscale(#{$value})"};
}

// Ie hack

@mixin iehack() {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        @content;
    }
}

// Placeholder

@mixin placeholder() {
    &::-webkit-input-placeholder {
        @content;
    }

    &::-moz-placeholder {
        @content;
    }

    &:-ms-input-placeholder {
        @content;
    }

    &:-moz-placeholder {
        @content;
    }
}

// Translate

@mixin translate($x, $y) {
    transform: translate($x, $y);
}
