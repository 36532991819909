//
// List group
// --------------------------------------------------


// Interactive list items

.list-group-item-action {
  transition: $list-group-action-transition;
  font-weight: $list-group-action-font-weight;
  &:hover {
    box-shadow: $list-group-action-hover-box-shadow;
  }
}
.list-group-item.active {
  border-color: $list-group-border-color;
  border-left-color: $list-group-active-border-color;
  box-shadow: $list-group-action-active-box-shadow;
}


// Contextual variants

@each $color, $value in $theme-colors {
  @include list-group-item-variant-custom($color, theme-color-level($color, -11), theme-color-level($color, 2));
}

.list-group-item-secondary,
.list-group-item-light {
  color: $body-color !important;
  a { color: $body-color !important; }
}
.list-group-item-secondary {
  background-color: $gray-100 !important;
}
.list-group-item-dark,
.list-group-item-dark:hover,
.list-group-item-dark:focus {
  background-color: theme-color('dark') !important;
  color: $white !important;
}
.list-group-item-dark:hover,
.list-group-item-dark:focus,
.list-group-item-dark.active {
  box-shadow: $list-group-action-active-box-shadow !important;
}

// Flush inside card
.category-card .list-group-flush {
  .list-group-item-action:hover,
  .list-group-item-action.active {
    box-shadow: none;
  }
  .list-group-item {
    padding: {
      right: 0;
      left: 0;
    }
  }
}
