$smart-link-background-color: #dedede !default;
$smart-link-border-radius: 2.5rem !default;


.smart-link {
  display: flex;
  background: $smart-link-background-color;
  border-radius: $smart-link-border-radius;

  @include breakpoint(576px) {
    display: block;
  }

  .img {
    img {
      max-width: 200px;
      border-radius: 2rem;

      @include breakpoint(576px) {
        max-width: 100%;
        width: 100%;

        border-bottom-right-radius: unset;
        border-bottom-left-radius: unset;
      }
    }
  }

  .content {

    @include breakpoint(576px) {
      padding: 1.5rem;
    }

    .smart-link-title {
      a {
        font-size: 2.5rem;
        line-height: 2.5rem;
        font-weight: bold;
      }
    }
  }
}
