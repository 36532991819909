// Footer

.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: rem(30px) 0;
    padding-top: rem(15px);
    font-size: rem(16px);
    text-transform: uppercase;
    text-align: right;

    @include media-breakpoint-down(sm) {
        padding-left: 1.55rem;
        padding-right: 1.55rem;
    }

    .recaptcha-terms {
        font-size: 8px;
    }

    .footer-link {
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    .fb-link {
        color: #1777f1;
        font-size: 2em;
    }
}
