/* General */

::selection {
    color: $color-white;
    background: $color-primary;
}

body {
    padding-top: rem(30px);
    font-family: 'Nudista-Light', sans-serif;
    line-height: 1.4;

    @include media-breakpoint-up(lg) {
        padding-top: rem(50px);
    }

    .modal-backdrop {
        width: 100%;
        height: 100%;
    }
}

b,
strong {
    font-weight: $font-weight-bold;
    font-family: 'Nudista-SemiBold', sans-serif;
}

a {
    text-decoration: underline;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin-bottom: rem(30px);
    text-transform: uppercase;
}

.layout-row {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @include media-breakpoint-up(lg) {
        flex-direction: row;
        flex-wrap: nowrap;
    }

    .layout-row-left {
        margin-bottom: rem(30px);

        @include media-breakpoint-up(lg) {
            flex: 0 0 rem(222px);
            margin-bottom: 0;
        }
    }

    .layout-row-right {
        flex: 1;
    }

    .layout-row-shadow {
        @include media-breakpoint-up(md) {
            padding: 0 rem(53px);
            box-shadow: 0 0 0.625rem $color-gray;
            position: relative;
        }
    }
}

.mb-90 {
    margin-bottom: rem(50px);

    @include media-breakpoint-up(md) {
        margin-bottom: rem(90px);
    }
}

.btn {
    cursor: pointer;

    .btn-success {
        color: $white;
        background-color: $badge-success;
        border-color: $badge-success;
    }
}

form label {
    color: $black !important;
    font-size: 1.125rem !important;
}

a:hover {
    color: $black;
}

form .error {
    color: $badge-danger;
    font-weight: $font-weight-bold;

    position: relative;
    min-width: 400px;
    display: inline-block;
}

.services-wrapper {

    .meeb__admin-static-content-wrapper .meeb__admin-static-content-info {
        top: -9px;
        right: 60px;
    }

    h3, h4 {
        margin-bottom: 0;
    }

    h4 {
        color: black;
        font-weight: bold;
        font-size: 1rem;
    }

    ul {
        padding-left: 0;
        margin-top: rem(5px);

        li {
            list-style-type: none;
            padding-left: 25px;
            text-indent: -24px;

            &:before {
                content: '—';
                padding-right: 5px;
            }
        }
    }

    .info-box {
        background: #F0F0F0;

        padding: rem(15px) 0 rem(25px) rem(25px);
        margin-left: rem(30px);
        margin-bottom: rem(30px);
        float: right;
        width: 280px;

        @include media-breakpoint-down(md) {
            float: unset;
            width: 100%;
            margin-left: 0;
        }

        p {
            padding: 0;
            margin-top: 0.4rem;
            margin-bottom: 0.4rem;
        }
    }
}

.thanks-message-wrapper {
    position: absolute;
    top: 30px;

    -moz-animation: cssAnimation 0s ease-in 10s forwards;
    /* Firefox */
    -webkit-animation: cssAnimation 0s ease-in 10s forwards;
    /* Safari and Chrome */
    -o-animation: cssAnimation 0s ease-in 10s forwards;
    /* Opera */
    animation: cssAnimation 0s ease-in 10s forwards;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

@keyframes cssAnimation {
    to {
        width:0;
        height:0;
        overflow:hidden;
    }
}
@-webkit-keyframes cssAnimation {
    to {
        width:0;
        height:0;
        visibility:hidden;
    }
}

.grecaptcha-badge { visibility: hidden; }

.container {
    @include media-breakpoint-down(sm) {
        padding-left: 0;
        padding-right: 0;
    }
}

.row {
    @include media-breakpoint-down(sm) {
        margin-left: 0;
        margin-right: 0;
    }
}

.table-open-wrap {
    @include media-breakpoint-down(sm) {
        margin: 0 !important;
    }
}

.order-form {
    @include media-breakpoint-down(sm) {
        margin: 0 !important;
    }
}

.modal-content {
    background-clip: unset;

    .modal-title {
        font-size: 1.875rem;
    }
}

main {
    min-height: 1000px;
}

.cookies-consent__wrapper .cookies-consent .switchery > small {
    top: -2px;
}

@media only screen and (max-width: 768px) {
    .cookies-consent__wrapper {
        height: 100vh;
    }
    .cookies-consent {
        width: calc(100% - 15px);
    }
}

.cookies-consent .cookies-consent__btns {
    margin-bottom: 0;
}

.cookies-consent__settings-item {
    border: 1px solid black;
    margin-bottom: 30px;
    border-radius: 0;
    font-family: Nudista-Light, sans-serif;
}

.cookies-consent__settings-item--more {
    font-family: Nudista-Light, sans-serif;
    font-weight: 200;
    line-height: 32px;
}

.cookies-consent__wrapper {
    background: rgba(0,0,0,0.5);
}

.cookies-consent {
    border-radius: 0;
    background: white;
    color: black;
    padding: 50px;
    max-width: unset;

    .cookies-consent__btn {
        border-radius: 0;
        background: white;
        border: 1px solid black;
        color: black;
        text-transform: uppercase;
        font-family: Nudista-Light, sans-serif;

        font-size: 16px;
        padding: 12px 18px;
        font-weight: 500;

        &:hover {
            color: white;
            background: black;
        }

        &.cookies-consent__btn--success {
            background: #ec955b;
            border: 1px solid transparent;
            text-transform: uppercase;
            margin-right: 0 !important;
            color: white;

            &:hover {
                background: #E18C5D;
                border-color: #d1851c;
            }
        }
    }

    .cookies-consent__footer {
        padding: 0;
    }

    .cookies-consent__footer img {
        width: 270px;
        display: none;
    }
}

.cookies-consent__settings-item {
    background: white;
    color: black;
    font-weight: 500;

    padding: 12px 18px;
}

.cookies-consent__settings-item--more {
    color: black;
}

.cookies-consent__wrapper .cookies-consent .switchery > small {
    top: -2px;
}

.cookies-consent__wrapper .cookies-consent p {
    color: black !important;
    font-family: Nudista-Light, sans-serif;
    line-height: 32px;
}

@media only screen and (max-width: 768px) {
    .cookies-consent {
        width: calc(100% - 15px);
    }

    .cookies-consent__wrapper {
        top: 15px;
    }
}

.price-list-wrapper {
    .price-list-category {
        line-height: 1.5;

        h5 {
            font-weight: bold;
            margin-bottom: 5px;
            margin-top: 30px;
            padding-left: 10px;

            &.first {
                margin-top: 0;
            }
        }

        .price-list-items {
            .price-list-item {
                &:nth-child(odd) {
                    background: #f0f0f0;
                }

                padding: 10px;
                @include media-breakpoint-down(md) {
                    text-align: center;

                    .question-mark-wrapper {
                        display: block;
                    }
                }

                .price {
                    float: right;

                    @include media-breakpoint-down(md) {
                        float: none;
                        padding-top: 25px;
                        font-family: Nudista-SemiBold, sans-serif;
                        font-weight: bold;
                    }
                }
            }
        }
    }

    .question-mark-wrapper {
        position: relative;

        .question-box {
            background: #ec955b;
            color: white;

            padding: 20px;
            min-width: 300px;

            position: absolute;
            bottom: 35px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 9;

            .close-icon {
                width: 22px;
                cursor: pointer;

                .st0{ fill:#FFFFFF; }
                .st1{ fill:#ED965D; }
            }

            @include media-breakpoint-down(sm) {
                width: 100%;
                bottom: -50%;
            }
        }

        .question-mark {
            width: 22px;
            position: relative;
            top: -3px;

            cursor: pointer;
            z-index: 8;
        }
    }
}

.overflow-visible {
    overflow: visible !important;
}