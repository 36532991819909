//
// Images & Figures
// --------------------------------------------------


// Figures

.figure {
  padding: $figure-padding;
  border: $figure-border-width solid $figure-border-color;
  background-color: $figure-bg;
}