//
// user-variables.scss
// Use this to overwrite Bootstrap and CreateX variables
//

// Example of a variable override to change Dashkit's background color
// Remove the "//" to comment it in and see it in action!
// $body-bg: #12263F;

$line-border-width: 1px;

$text-large-font-size: 2rem;
$text-base-font-size: 1rem;
$text-small-font-size: 0.5rem;

$arrow-size: 20px;
$arrow-margin: 25px;

$preview-image-height: 180px;
$featured-entry-image-height: 50px;

$color-attribute-size: 25px;
$color-attribute-border-radius: 0;
$color-attribute-border: 1px solid black;
$color-attribute-border-hover: $primary;