$toolbar-background: white !default;
$toolbar-border-radius: 56px !default;
$toolbar-border-color: #dcdcdc !default;
$toolbar-border-width: 1px !default;

$toolbar-color-red: #d90000 !default;

$toolbar-padding: 5px !default;

$toolbar-width: 110px !default;

$toolbar-z-index: 10000 !default;
$toolbar-box-shadow: 0 0 25px 0 rgba(0,0,0,0.10) !default;

$toolbar-nav-link-icon-color: #737678 !default;
$toolbar-nav-link-bg-color: white !default;
$toolbar-nav-link-bg-hover: #f2f2f2 !default;
$toolbar-nav-link-border-radius: 10px !default;

$toolbar-nav-link-icon-size: 1.7em !default;
$toolbar-nav-link-title-size: 0.75em !default;

$toolbar-href-color: black !default;

$overlay-href-color: #fff !default;
$overlay-href-color-hover: #22a2e4 !default;

$font-size-lg: 1.15em !default;

@mixin drag-select-none() {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

@mixin toolbar-position($margin, $position: 'top-left') {
  @if (str-index($position, 'top')) {
    top: $margin;
  } @else {
    bottom: $margin;
  }

  @if (str-index($position, 'left')) {
    left: $margin;
  } @else {
    right: $margin;
  }
}

@mixin collapsed-toolbar($margin, $position: 'top-left') {
  @include toolbar-position($margin, $position);

  display: flex;
  opacity: 0.5;
}

.shadow-drop-center {
  -webkit-animation: shadow-drop-center 0.4s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  animation: shadow-drop-center 0.4s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

@-webkit-keyframes shadow-drop-center {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
    box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
  }
}
@keyframes shadow-drop-center {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
    box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
  }
}

@keyframes slide-out-tl {
  0% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0) scale(1);
    transform-origin: top left;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-100vh) translateX(-100vw);
    transform: translateY(-100vh) translateX(-100vw) scale(0);
    transform-origin: top left;
    opacity: 0;
  }
}

@keyframes slide-out-tr {
  0% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0) scale(1);
    transform-origin: top right;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-100vh) translateX(100vw);
    transform: translateY(-100vh) translateX(100vw) scale(0);
    transform-origin: top right;
    opacity: 0;
  }
}

@keyframes slide-out-bl {
  0% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0) scale(1);
    transform-origin: bottom left;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(100vh) translateX(-100vw);
    transform: translateY(100vh) translateX(-100vw) scale(0);
    transform-origin: bottom left;
    opacity: 0;
  }
}

@keyframes slide-out-br {
  0% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0) scale(1);
    transform-origin: bottom right;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(100vh) translateX(100vw);
    transform: translateY(100vh) translateX(100vw) scale(0);
    transform-origin: bottom right;
    opacity: 0;
  }
}

.meeb__has-toolbar {
  &.meeb__toolbar-top {
    margin-top: 50px;

    .navbar {
      top: 50px;
    }
  }
}

.meeb__admin-toolbar {
  position: fixed;
  width: $toolbar-width;

  top: 10%;
  right: 50px;

  background: $toolbar-background;
  border-radius: $toolbar-border-radius;
  border: $toolbar-border-width solid $toolbar-border-color;

  font-family: "open sans",Helvetica Neue,Helvetica,Arial,sans-serif;
  box-shadow: $toolbar-box-shadow;
  z-index: $toolbar-z-index;

  transition: opacity .35s ease-out;

  &.bottom {
    top: unset;
    bottom: 0;
  }

  a {
    color: $toolbar-href-color;
  }

  &.fade-tl {
    animation: slide-out-tl 0.5s forwards;
  }

  &.fade-tr {
    animation: slide-out-tr 0.5s forwards;
  }

  &.fade-bl {
    animation: slide-out-bl 0.5s forwards;
  }

  &.fade-br {
    animation: slide-out-br 0.5s forwards;
  }
}

.meeb__admin-toolbar__container {
  padding: $toolbar-padding;
  width: 100%;
  height: 100%;
  position: relative;
}

.meeb__admin-toolbar__nav {
  margin: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 0;

  .meeb__admin-text-red {
    color: $toolbar-color-red !important;
  }

  & > .meeb__admin-toolbar__nav__item {
    position: relative;
    display: flex;

    .warning-icon {
      position: absolute;
      top: 5px;
      left: 5px;
    }

    & > a {
      padding: 14px 10px;
      align-items: center;
      font-weight: bold;
      font-size: 14px;
      background: $toolbar-nav-link-bg-color;
      border-radius: $toolbar-nav-link-border-radius;

      .fa, .fas {
        color: $toolbar-nav-link-icon-color;
        font-size: $toolbar-nav-link-icon-size;
      }

      .meeb__admin-toolbar-title {
        font-size: $toolbar-nav-link-title-size;
      }

      &:hover, &.active {
        background: $toolbar-nav-link-bg-hover;
      }
    }
  }
}

.meeb__admin-drag {
  cursor: move;
}

.meeb__admin-toolbar__right {
  float: right;
}

.meeb__admin-toolbar__nav--right {
  margin-right: 40px;
}

.meeb__admin-edit-wrapper {
  position: relative;

  .meeb__admin-edit-link {
    position: absolute;
    width: 71px;
    height: 71px;
    top: -30px;
    right: -15px;
    display: none;
    transition: 0.5s;

    z-index: 9999;
  }
}

.meeb__admin-static-content-wrapper {
  position: relative;
  display: block;

  .meeb__admin-static-content-info {
    position: absolute;
    width: 71px;
    height: 71px;
    top: -30px;
    right: 30px;
    display: none;
    transition: 0.5s;
    z-index: 999;

    &.meeb__admin-static-content-detached {
      left: 30px;
      top: 15px;

      &.reverse-offset-top {
        top: -15px;
      }

      &.reverse-offset-right {
        left: -30px;
      }
    }

    &.reverse-offset-top {
      top: 30px;
    }

    &.reverse-offset-right {
      right: -30px
    }

    a {
      display: block;
    }
  }

  &:hover .meeb__admin-static-content-info {

  }
}

.meeb__admin-show-links {
  .meeb__admin-static-content-info, .meeb__admin-edit-link {
    display: block;
    transition: 0.5s;
  }
}

.meeb__admin-hide-links {
  .meeb__admin-static-content-info, .meeb__admin-edit-link {
    display: none;
    transition: 0.5s;
  }
}

.meeb__admin-toolbar-nav-item-versions {
  position: relative;

  .meeb__admin-tool-list {
    position: absolute;
    top: 0;

    width: 835px;
    padding: 15px;

    list-style-type: none;
    transition: width ease-in-out 0.3s, box-shadow ease-in-out 0.3s, border ease-in-out 0.3s;



    background: #fff;
    border: 1px solid #dcdcdc;
    box-shadow: 0 0 25px 0 rgba(0,0,0,0.1);

    max-height: 535px;
    overflow: auto;

    &.meeb__list-pos-left {
      right: 99px;
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
    }

    &.meeb__list-pos-right {
      left: 99px;
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
    }

    &.meeb__admin-tool-list-hidden {
      width: 0;
      overflow: hidden;
      background: transparent;
      box-shadow: none;
      border: 1px solid transparent;

      li {
        display: none;
      }
    }

    li {
      display: flex;
      flex-wrap: nowrap;
      overflow: hidden;

      span {
        display: block;
        text-align: left;
        width: 160px;
        white-space: nowrap;
      }

      .meeb__admin-version-link {
        font-weight: bold;
      }
    }
  }
}

.meeb__admin_static_block_wrapper {
  display: inline-block;
  position: relative;

  .meeb__admin_static_block_inner {
    display: none;

    min-width: 100px;
    top: 0;
    background-color: #2F4050;
    padding: 10px;
    border-left: 4px solid #22a2e4;
    border-radius: 0px;
    color: #a7b1c2;
    transition: 0.5s;
    font-size: 12px;
    z-index: 999;
    display: none;

    a {
      color: #a7b1c2;
      text-decoration: underline;
    }
  }

  &:hover .meeb__admin_static_block_inner, &.meeb__open .meeb__admin_static_block_inner {
    display: none;
    position: absolute;
  }
}

.meeb__admin-overlay-open {
  overflow: hidden;

  &:before {
    display: block;
    content: "";
    background-color: rgba(0, 0, 0, 0.85) !important;
    opacity: 1 !important;
    transform: unset !important;
    z-index: 9999;

    position: absolute;
    top: 0;

    width: 100%;
    height: 100%;
  }

  .meeb__admin-overlay {
    display: block !important;
  }
}

.meeb__admin-overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 99999;

  .meeb__admin-overlay-nav-wrapper {
    overflow-x: hidden;
    overflow-y: scroll;
    height: calc(100vh - 200px);
  }

  .meeb__admin-content {
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 75px;

    .meeb__tiles_title {
      color: white;
      font-size: $font-size-lg;
    }

    .meeb__tiles_wrapper {
      overflow-y: scroll;
      height: calc(100vh - 200px);

      .meeb__tile_wrapper {
        display: flex;
        flex-wrap: wrap;
      }
    }

    .meeb__admin-overlay-tile {
      height: 150px;
      width: 150px;
      background-color: greenyellow;

      display: block;
      a {
        display: flex;
        text-align: center;
        height: 100%;

        p {
          margin: auto;
        }
      }
    }

    .nav {
      .nav-item {
        a {
          color: $overlay-href-color;
          padding: 0;
          &:hover {
            color: $overlay-href-color-hover;
          }
        }
      }

      .nav {
        padding-left: 15px;
      }
    }
  }
}

.meeb__admin-toolbar-collapsed {
  background: white;
  border-radius: 50%;

  height: 85px;
  width: 85px;

  z-index: 9999;
  position: fixed;

  display: none;
  align-items: center;

  @include drag-select-none();
  img {
    width: 50px;
    height: 68px;

    margin: auto;
    @include drag-select-none();
  }
}

.meeb__admin-toolbar-top-left {
  .meeb__admin-toolbar-collapsed {
    @include collapsed-toolbar(25px, 'top-left');
  }

  .meeb__admin-toolbar {
    opacity: 0.5;
  }
}

.meeb__admin-toolbar-top-right {
  .meeb__admin-toolbar-collapsed {
    @include  collapsed-toolbar(25px, 'top-right');
  }

  .meeb__admin-toolbar {
    opacity: 0.5;
  }
}

.meeb__admin-toolbar-bottom-left {
  .meeb__admin-toolbar-collapsed {
    @include  collapsed-toolbar(25px, 'bottom-left');
  }

  .meeb__admin-toolbar {
    opacity: 0.5;
  }
}

.meeb__admin-toolbar-bottom-right {
  .meeb__admin-toolbar-collapsed {
    @include collapsed-toolbar(25px, 'bottom-right');
  }

  .meeb__admin-toolbar {
    opacity: 0.5;
  }
}

.meeb__admin-toolbar-snapped {

  &.meeb__admin-toolbar-top-left {
    .meeb__admin-toolbar {
      animation: slide-out-tl 0.5s forwards;
    }
  }

  &.meeb__admin-toolbar-top-right {
    .meeb__admin-toolbar {
      animation: slide-out-tr 0.5s forwards;
    }
  }

  &.meeb__admin-toolbar-bottom-left {
    .meeb__admin-toolbar {
      animation: slide-out-bl 0.5s forwards;
    }
  }

  &.meeb__admin-toolbar-bottom-right {
    .meeb__admin-toolbar {
      animation: slide-out-br 0.5s forwards;
    }
  }

  .meeb__admin-toolbar-collapsed {
    display: flex !important;
    opacity: 0.95;
    transition: opacity 0.35s ease-out;
    animation: shadow-drop-center 0.35s forwards;

    &:hover {
      opacity: 1;
      cursor: pointer;
    }

  }
}