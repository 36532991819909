//
// user.scss
// Use this to write your custom SCSS
//

@import "user-mixins";
@import "background";
@import "floating-labels";
@import "admin-toolbar";
@import "admin-overlay";
@import "smart-links";

.bg-primary {
  background: $primary !important;
}

body {
  font-feature-settings: 'tnum';
}

.cursor-pointer {
  cursor: pointer!important;
}

.badge {
  padding-bottom: unset;
  border-radius: unset;
  line-height: unset;
}

.badge-item {
  padding-bottom: $badge-padding-y + .05em;
  border-radius: $badge-border-radius;
  line-height: .9;
}

.widget-featured-entries {
  .featured-entry-thumb {
    .item-remove-btn {
      display: inline-block;
      width: 1.125rem;
      color: white;
      overflow: hidden;
    }
  }
}

.mega-dropdown {
  .nav-link {
    font-weight: $font-weight-bold;
  }

  .feather-more-horizontal {
    color: $secondary;
  }

  .dropdown-menu {
    .dropdown-inner {
      ul:not(.dropdown-menu) {
        display: flex;
        flex-wrap: wrap;
        width: calc(17rem * 4);
      }
    }
  }

  .dropdown-item {
    padding: 0;

    .dropdown-menu {
      display: block;
      position: unset;
      border: none !important;
      padding-left: 10px;
    }
  }
}

.navbar-expand-lg {
  .navbar-lang-switcher {
    border-left: 1px solid #e7e7e7;
    padding-top: 0;

    .dropdown-item {
      font-weight: bold;
      font-size: 0.7em;
    }

    .dropdown-menu {
      width: 10rem;
    }
  }
}

.navbar-brand {
  max-height: 80px;
  position: relative;

  &.navbar-basic .navbar-img {
    max-height: calc(80px - 2.5rem);
  }

  &.navbar-absolute {
    width: 150px;
    height: 80px;

    .navbar-img {
      position: absolute;
      height: 150px;
      width: auto;
      max-width: unset;
    }
  }
}

.table-anchored {
  a {
    color: $black;

    &:hover {
      text-decoration: none;
    }
  }
}

.icon-box-icon {
  i {
    line-height: 4.5rem;
  }
}

.offcanvas-header .close {
  line-height: 46px;
}

.toast-wrapper {
  position: absolute;
  top: 25px;
  left: 25px;
  z-index: 999999;

  .toast {
    width: 250px;
  }
}

.line-bottom-100 {
  border-bottom: $line-border-width solid $gray-100;
}

.line-bottom-200 {
  border-bottom: $line-border-width solid $gray-200;
}

.line-bottom-300 {
  border-bottom: $line-border-width solid $gray-300;
}

.line-bottom-400 {
  border-bottom: $line-border-width solid $gray-400;
}

.line-bottom-500 {
  border-bottom: $line-border-width solid $gray-500;
}

.line-bottom-600 {
  border-bottom: $line-border-width solid $gray-600;
}

.line-bottom-700 {
  border-bottom: $line-border-width solid $gray-700;
}

.line-bottom-800 {
  border-bottom: $line-border-width solid $gray-800;
}

.line-bottom-900 {
  border-bottom: $line-border-width solid $gray-900;
}

.line-top-100 {
  border-top: $line-border-width solid $gray-100;
}

.line-top-200 {
  border-top: $line-border-width solid $gray-200;
}

.line-top-300 {
  border-top: $line-border-width solid $gray-300;
}

.line-top-400 {
  border-top: $line-border-width solid $gray-400;
}

.line-top-500 {
  border-top: $line-border-width solid $gray-500;
}

.line-top-600 {
  border-top: $line-border-width solid $gray-600;
}

.line-top-700 {
  border-top: $line-border-width solid $gray-700;
}

.line-top-800 {
  border-top: $line-border-width solid $gray-800;
}

.line-top-900 {
  border-top: $line-border-width solid $gray-900;
}

.line-bottom-100 {
  border-bottom: $line-border-width solid $gray-100;
}

.line-bottom-200 {
  border-bottom: $line-border-width solid $gray-200;
}

.line-bottom-300 {
  border-bottom: $line-border-width solid $gray-300;
}

.line-bottom-400 {
  border-bottom: $line-border-width solid $gray-400;
}

.line-bottom-500 {
  border-bottom: $line-border-width solid $gray-500;
}

.line-bottom-600 {
  border-bottom: $line-border-width solid $gray-600;
}

.line-bottom-700 {
  border-bottom: $line-border-width solid $gray-700;
}

.line-bottom-800 {
  border-bottom: $line-border-width solid $gray-800;
}

.line-bottom-900 {
  border-bottom: $line-border-width solid $gray-900;
}

.bg-gray-100 {
  background: $gray-100;
}

.bg-gray-200 {
  background: $gray-200;
}

.bg-gray-300 {
  background: $gray-300;
}

.bg-gray-400 {
  background: $gray-400;
}

.bg-gray-500 {
  background: $gray-500;
}

.bg-gray-600 {
  background: $gray-600;
}

.bg-gray-700 {
  background: $gray-700;
}

.bg-gray-800 {
  background: $gray-800;
}

.bg-gray-900 {
  background: $gray-900;
}

.text-small {
  font-size: $text-small-font-size;
}

.text-base {
  font-size: $text-base-font-size;
}

.text-large {
  font-size: $text-large-font-size;
}

@keyframes placeHolderShimmer{
  0%{
    background-position: -468px 0
  }
  100%{
    background-position: 468px 0
  }
}

.google-maps {
  position: relative;
  padding-bottom: 100%;
  height: 0;
  overflow: hidden;

  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
}

.widget-categories ul>li.selected a, .widget-links ul>li.selected a {
  color: $widget-categories-link-hover-color;
  text-decoration: none;
}

.arrow-bottom {
  position: relative;
  margin-bottom: $arrow-margin;

  &:after {
    content:'';
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-top: solid $arrow-size;
    border-left: solid $arrow-size transparent;
    border-right: solid $arrow-size transparent;
  }

  &.arrow-primary {
    &:after {
      border-top-color: theme-color-level('primary', $alert-bg-level);
    }
  }

  &.arrow-secondary {
    &:after {
      border-top-color: theme-color-level('secondary', $alert-bg-level);
    }
  }
}

@mixin toastr($color) {
  background-color: $color;
  background-image: none;
  opacity: 0.95;
}

#toast-container {
  .toast {
    &.toast-success {
      @include toastr($success);
    }

    &.toast-warning {
      @include toastr($warning);
    }

    &.toast-error {
      @include toastr($danger);
    }

    &.toast-primary {
      @include toastr($primary);
    }

    &.toast-secondary {
      @include toastr($secondary);
    }
  }
}

.forgotten-pass-link {
  position: absolute;
  bottom: 23px;
  right: 0px;
}

.i-checks {
  input {
    margin-right: 5px;
  }
}

form {
  .alert {
    p {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
}

.article-detail {
  .gallery-item {
    width: 20%;
    float: left;
    margin-right: 15px;
    margin-bottom: 15px;
  }
}

.background-img-wrapper {
  width: unset !important;
  div {
    background-size: cover;
    background-repeat: no-repeat;

    width: 100%;
    height: 100%;
  }
}

.product-thumb .background-img-wrapper {
  height: $preview-image-height;
}

.widget-featured-entries {
  .media {
    .background-img-wrapper {
      height: $featured-entry-image-height;
    }
  }
}

.product-gallery .product-thumbnails {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;

  @include scrollbar(10px, #f1f1f1, #888, #555);
}

.product-gallery-wrapper {
  position: relative;

  &.disabled {
    &:after {
      display: block;
      position: absolute;
      top: 0;
      left: 0;

      height: 100%;
      width: 100%;

      background: black;
      opacity: 0.65;
      content: "";

      z-index: 99;
    }

    .disabled-message {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      text-align: center;

      z-index: 999;

      .message {
        height: 100%;
        display: inline-block;
        padding-top: calc(50% - 2rem);
        opacity: 1;
      }
    }
  }
}

.scroll-lock {
  overflow: hidden;
}

.mature-modal-wrapper {
  position: fixed;

  left: 0;
  top: 0;

  height: 100%;
  width: 100%;

  background: rgba(0, 0, 0, 0.75);
  z-index: 9999;

  .mature-modal {
    background: white;
    color: black;

    width: 600px;
    height: 300px;

    position: absolute;
    top: calc(50% - 150px);
    left: calc(50% - 300px);

    .row {
      height: 100%;
    }
  }
}

.divider {
  background: $primary;
  border-radius: 5px;

  &.divider-horizontal {
    height: 2px;
    width: 100%;

    margin: 1rem 10px;
  }

  &.divider-vertical {
    width: 2px;
    height: 100%;
    margin: auto;
  }
}

.social-button {
  width: 100%;
  border-radius: 4px;
}

.social-button .fa-google {
  background: conic-gradient(from -45deg, #ea4335 110deg, #4285f4 90deg 180deg, #34a853 180deg 270deg, #fbbc05 270deg) 73% 55%/150% 150% no-repeat;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
}

.ui-range-values {
  .ui-range-value-min, .ui-range-value-max {
    font-size: 0.835rem;
  }
}

.products-filter-form .widget .widget-collapsable {
  .fa {
    font-weight: 400;
    display: inline-block;
  }

  .fa-plus {
    display: none;
  }

  &.collapsed {
    .fa-plus {
      display: inline-block;
    }
    .fa-minus {
      display: none;
    }
  }
}

.switchery-wrapper input[type=checkbox] {
  margin-right: 10px;
}

.navbar-btns .navbar-btns-inner .navbar-btn .search-title {
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.navbar-toggler {
  position: relative;
  width: 30px;
  height: 28px;
  top: 3px;

  &:focus {
    outline: none;
  }

  &:not(.collapsed) {
    .line:nth-child(1) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
      top: 0px;
      left: 0px;
    }

    .line:nth-child(2) {
      width: 0%;
      opacity: 0;
    }

    .line:nth-child(3) {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
      top: 20px;
      left: 0px;
    }
  }

  .line {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: $primary;
    border-radius: 9px;
    opacity: 1;
    left: 0;

    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;

    &:nth-child(1) {
      top: 0px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }

    &:nth-child(2) {
      top: 10px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }

    &:nth-child(3) {
      top: 20px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }
  }

  /*&::after {
      content: "";

      @include svgIcon("respo", rem(30px), rem(28px));
  }*/
}

.label-list {
  list-style-type: none;
  padding-left: 15px;
}

.price-wrapper {
  display: flex;

  @include media-breakpoint-down(md) {
    justify-content: center;
  }
}

.navbar-expand-lg .navbar-btns .navbar-btn.navbar-toggler {
  padding-left: 0;
  min-height: unset;
  width: 50px;
  height: 50px;
  top: 3px;

  .navbar-lines-wrapper {
    position: relative;
    top: -14px;
    left: 2px;
  }
}

.cart-item-thumb {
  @include media-breakpoint-down(md) {
    margin-bottom: 25px;
  }
}

.color-list {
  display: inline-flex;
  flex-direction: row;

  justify-content: start;

  .color-item {
    width: $color-attribute-size;
    height: $color-attribute-size;

    border: $color-attribute-border;
    border-radius: $color-attribute-border-radius;
    margin-right: 5px;

    a {
      display: block;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

#frm-search-searchForm .input-group-append .btn.btn-primary {
  height: calc(1.5em + 1rem + 2px);
}

.product-files-list {
  width: 100%;
  overflow-x: auto;

  display: flex;
  justify-content: start;

  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
  }

  .file-icon {
    max-width: 100px;
  }
}

@media (max-width: 991.98px){
  .cookies-consent__wrapper {
    height: 100%;
    position: absolute;
  }

  .cookies-consent {
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    bottom: unset !important;
    margin: 0;
    height: auto;
    max-height: calc(100% - 100px);
    max-height: calc(-webkit-fill-available - 100px);
    overflow-y: auto;
  }
}

.cookies-consent__btns a {
  text-decoration: none !important;
}

.cookies-consent__wrapper .cookies-consent .switchery > small {
  top: -2px;
}

.packeta-selector-open, .ppl-selector-open {
  display: none;
}

.shipping-selected {
  .packeta-selector-open, .ppl-selector-open {
    display: inline-block;
  }
}