//
// Nav
// --------------------------------------------------


// Base styles

.nav-link {
  transition: $nav-link-transition;
  font-weight: $nav-link-font-weight;
  .feather {
    @include feather-icon-size($nav-link-icon-size);
  }
}


// Nav tabs

.nav-tabs {
  margin-bottom: ($spacer * 1.25);
  .nav-item {
    margin-bottom: -($nav-tabs-border-width * 2);
    .dropdown-menu {
      margin-top: -($nav-tabs-border-width * 2);
    }
  }
  .nav-link {
    border: 0;
    border-bottom: ($nav-tabs-border-width * 2) solid transparent;
    color: $nav-tabs-link-color;
    font-weight: $nav-link-font-weight;
    &:hover {
      color: $nav-tabs-link-hover-color;
    }
  }
}


// Nav pills

.nav-pills {
  .nav-link {
    color: $nav-pills-link-color;
    &:hover:not(.active):not([aria-expanded=true]) {
      color: $nav-pills-link-hover-color;
      background-color: $nav-pills-link-hover-bg;
    }
    &.disabled {
      color: $nav-link-disabled-color;
      &:hover:not(.active) {
        background-color: transparent;
      }
    }
    .feather {
      margin-top: -.125rem; 
    }
  }
}
