body.overlay-show {
  overflow: hidden;
}

.meeb__admin_overlay {
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.85);
  z-index: 9999;
  opacity: 0;
  pointer-events: none;

  padding: 50px 250px;

  transition: opacity 0.25s ease-in-out;

  &.show {
    opacity: 1;
    pointer-events: all;
  }

  .table-wrapper {
    max-height: calc(100vh - 270px);
    overflow: scroll;
  }

  .meeb__admin-iframe {
    margin: auto;
    display: block;
  }
}

#translation-table {
  tr.selected {
    background-color: rgba(255, 255, 255, 0.75);
  }
}

#frm-adminOverlay-rightLanguageForm {
  label {
    color: white;
  }
}

body.page-overlay {
  .meeb__moset-col-wrapper {
    position: relative;

    &:after {
      content: "";
      z-index: 99;

      width: 100%;
      height: 100%;

      position: absolute;
      top: 0;
      left: 0;

      background: black;
      opacity: 0.3;

      display: none;
      pointer-events: none;

      cursor: pointer;
    }

    &:hover {
      &:after {
        display: block;
      }
    }
  }
}

.meeb__admin-iframe {
  display: none;
}

.meeb__moset-highlight {
  background: white;
  z-index: 10001;
  position: relative;

  @each $bgColorClass, $color in $backgroundColors {
    .#{$bgColorClass} {
      @each $bgOpacityClass, $opacity in $backgroundOpacity {
        &.#{$bgOpacityClass} {
          background: rgba($color, $opacity) !important;
        }
      }
    }
  }

  .meeb__moset-col-wrapper:before {
    display: block;
    content: "";

    background: rgba(255, 0, 0, 0.25);
    position: absolute;

    top: unset;
    left: unset;
    height: unset;
    width: unset;
  }

  &:before {
    display: none;
    content: "";

    background: rgba(255, 0, 0, 0.25);
    position: absolute;
  }

  &.setup-top, .meeb__moset-col-wrapper.setup-top {
    &:before {
      top: 0;
      left: 0;

      width: 100%;
      height: map-get($spacers, 5);
      display: block;
    }

    &.setup-size-0:before {
      height: map-get($spacers, 0);
    }
    &.setup-size-1:before {
      height: map-get($spacers, 1);
    }
    &.setup-size-2:before {
      height: map-get($spacers, 2);
    }
    &.setup-size-3:before {
      height: map-get($spacers, 3);
    }
    &.setup-size-4:before {
      height: map-get($spacers, 4);
    }
    &.setup-size-5:before {
      height: map-get($spacers, 5);
    }
  }

  &.setup-left, .meeb__moset-col-wrapper.setup-left {
    &:before {
      top: 0;
      left: 0;

      width: map-get($spacers, 5);
      height: 100%;
      display: block;
    }
    &.setup-size-0:before {
      width: map-get($spacers, 0);
    }
    &.setup-size-1:before {
      width: map-get($spacers, 1);
    }
    &.setup-size-2:before {
      width: map-get($spacers, 2);
    }
    &.setup-size-3:before {
      width: map-get($spacers, 3);
    }
    &.setup-size-4:before {
      width: map-get($spacers, 4);
    }
    &.setup-size-5:before {
      width: map-get($spacers, 5);
    }
  }

  &.setup-right, .meeb__moset-col-wrapper.setup-right {
    &:before {
      top: 0;
      right: 0;

      width: map-get($spacers, 5);
      height: 100%;
      display: block;
    }
    &.setup-size-0:before {
      width: map-get($spacers, 0);
    }
    &.setup-size-1:before {
      width: map-get($spacers, 1);
    }
    &.setup-size-2:before {
      width: map-get($spacers, 2);
    }
    &.setup-size-3:before {
      width: map-get($spacers, 3);
    }
    &.setup-size-4:before {
      width: map-get($spacers, 4);
    }
    &.setup-size-5:before {
      width: map-get($spacers, 5);
    }
  }

  &.setup-bottom, .meeb__moset-col-wrapper.setup-bottom {
    &:before {
      bottom: 0;
      left: 0;

      width: 100%;
      height: map-get($spacers, 5);
      display: block;
    }
    &.setup-size-0:before {
      height: map-get($spacers, 0);
    }
    &.setup-size-1:before {
      height: map-get($spacers, 1);
    }
    &.setup-size-2:before {
      height: map-get($spacers, 2);
    }
    &.setup-size-3:before {
      height: map-get($spacers, 3);
    }
    &.setup-size-4:before {
      height: map-get($spacers, 4);
    }
    &.setup-size-5:before {
      height: map-get($spacers, 5);
    }
  }
}

.col-edit-form {
  position: absolute;
  width: 600px;

  background: white;
  border-radius: 12px;
  box-shadow: rgba(255, 255, 255, 0.35) 0px 5px 15px;

  z-index: 10002;

  .form-group {
    margin-bottom: 5px;
  }

  &.transparent {
    opacity: 0.5;
    //background: rgba(255, 255, 255, 0.5);
  }

  .nav.nav-tabs {
    li {
      padding: 5px 10px;
      border-radius: 5px 5px 0 0;

      &.active {
        background-color: #e7e7e7;
      }

      &.disabled {
        background-color: #f1f1f1;
        color: #777777;

        a:hover {
          text-decoration: none;
        }
      }
    }
  }
}

body.show-overlay {
  overflow: hidden;

  &:after {
    display: block;
    content: "";

    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;

    background: rgba(0, 0, 0, 0.6);
    z-index: 10000;
  }
}

