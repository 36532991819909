:root {
  --input-padding-x: 0.75rem;
  --input-padding-y: 0.75rem;
}

.form-floating-labels {
  .form-label-group {
    position: relative;
    margin-bottom: 1rem;

    textarea {
      padding-left: 0.75rem;
    }

    & > input:not(.btn), & > label {
      padding: $floating-label-input-padding-y $floating-label-input-padding-x;
    }

    & > label {
      position: absolute;
      top: $floating-label-top-offset;
      left: $floating-label-left-offset;
      display: block;
      width: 100%;
      margin-bottom: 0; /* Override default `<label>` margin */
      line-height: $floating-label-line-height;
      color: $floating-label-color !important;
      border-left: 1px solid transparent;
      border-radius: .25rem;
      transition: all .1s ease-in-out;
      cursor: text;
      pointer-events: none;

      &.text-area-label {
        left: $floating-label-text-area-offset;
        top: 0;
      }
    }

    input:not(.btn), textarea {
      &::-webkit-input-placeholder {
        color: transparent;
      }

      &::-ms-input-placeholder {
        color: transparent;
      }

      &::-moz-placeholder {
        color: transparent;
      }

      &::placeholder {
        color: transparent;
      }

      &:not(:placeholder-shown) {
        padding-top: ($floating-label-input-padding-y * 2) * (2 / 3);
        padding-bottom: $floating-label-input-padding-y / 3;

        & ~ label {
          padding-top: $floating-label-input-padding-y / 3;
          padding-bottom: $floating-label-input-padding-y / 3;
          font-size: $floating-label-floating-size;
          color: $floating-label-floating-color !important;
          left: $floating-label-floating-left-offset;
          top: $floating-label-floating-top-offset;
          user-select: none;
          cursor: unset;
        }

        &.text-area ~ label{
          top: $floating-label-floating-top-offset-textarea;
        }
      }
    }
  }
}

