// Homepage

.warning-message {
    background-color: $badge-danger;

    .text-danger {
        color: white !important;
    }
}

.homepage-top {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: rem(511px);
    background: url("#{$img-path}promo.jpg") no-repeat top center;
    background-size: cover;

    position: relative;

    &.menu-visible {
        z-index: -1;
    }

    @include media-breakpoint-up(md) {
        min-height: rem(581px);
        margin: 0 -#{rem(53px)};
    }

    .homepage-top-title {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: rem(110px) rem(20px) 0;
        color: $color-gray-second;
        text-align: center;

        @include media-breakpoint-up(md) {
            margin-top: 0;
            padding: 0 rem(70px);
        }

        @include media-breakpoint-down(sm) {
            padding-left: 0;
            padding-right: 0;
            padding-top: 0;

            margin-top: calc(-270px + (100vh - 475px));
        }
    }

    .btn {
        font-weight: 600;
    }

    .homepage-top-desc {
        margin-bottom: rem(30px);
        font-size: rem(30px);
        text-shadow: 0 0 rem(15px) $white;
        margin-top: 8.75rem;
        line-height: 1.3;

        @include media-breakpoint-up(md) {
            font-size: 2.74rem;
        }
    }
}

.header .navbar-collapse {
    flex-direction: column;
}

.homepage-measures {
    padding: 1.55rem;

    .homepage-meansures-title {
        margin-bottom: rem(16px);
        font-weight: $font-weight-bold;
        font-family: 'Nudista-SemiBold';
    }

    .homepage-meansures-desc {
        margin: 0;
    }
}

.layout-row-left {
    .homepage-measures {
        padding-left: 14px;
    }
}

.accordion .card-header .btn {
    font-family: 'Nudista-SemiBold';

    text-wrap: normal;

    > p {
        @include media-breakpoint-down(sm) {
            width: calc(100% - 3rem);
            white-space: pre-wrap;
            padding-bottom: 0;
            margin-bottom: 0;
        }
    }
}

.main-content {
    @include media-breakpoint-down(sm) {
        padding-left: 1.55rem;
        padding-right: 1.55rem;
    }
}

.contact-wrapper {
    .col-12 {
        @include media-breakpoint-down(sm) {
            padding-left: 0;
        }
    }
    .col-md-6 {
        @include media-breakpoint-down(sm) {
            padding-left: 0;
        }
    }
}

.thanks-message-inner {
    &.alert-success {
        background-color: #249700;
        color: white;
    }
}

.font-bold {
    font-family: Nudista-SemiBold, sans-serif;
}

