// Other

.contact-row {
    margin-bottom: rem(50px);

    .contact-item {
        padding: rem(20px) 0 rem(20px) rem(65px);
        background: url("#{$img-path}circle.png") no-repeat left center;

        &.item-mail {
            background: url("#{$img-path}motus_icon_email.svg") no-repeat left center;
            background-size: 50px 50px;
        }

        &.item-mobile {
            background: url("#{$img-path}motus_icon_mobile.svg") no-repeat left center;
            background-size: 50px 50px;
        }

        &.item-phone {
            background: url("#{$img-path}motus_icon_phone.svg") no-repeat left center;
            background-size: 50px 50px;
        }

        &.item-chat {
            background: url("#{$img-path}motus_icon_chat.svg") no-repeat left center;
            background-size: 50px 50px;
        }

        @include media-breakpoint-down(sm) {
            margin-left: -8px;
        }
    }
}

.order-form {
    margin: 0 -#{rem(15px)};
    padding: rem(15px);
    background: $color-gray-third;

    @include media-breakpoint-up(md) {
        margin: 0 -#{rem(53px)};
        padding: rem(52px);
    }

    .order-form-title {
        margin-bottom: rem(23px);
        text-transform: none;
    }

    .btn {
        height: auto;
        padding: rem(10px) rem(39px);
        font-size: rem(18px);
        text-transform: none;
    }

    .custom-file-label {
        z-index: 5;

        &::after {
            content: none;
        }
    }

    .form-file-label {
        display: inline-flex;
        margin: 0;
        padding: rem(10px) rem(52px);
        color: $white;
        background: rgba(40,40,40,.4);
        cursor: pointer;

        &:hover {
            background: rgba(40,40,40,.6);
        }
    }

    .form-file-input:not(.form-file-input--shown) {
        position: absolute;
        left: -9999rem;
        z-index: -1;
        opacity: 0;
    }

    .form-file {
        .file-list {
            list-style-type: none;
            margin-top: 10px;
            padding-left: 0;

            .file-close {
                span {
                    position: relative;
                    top: -5px;
                }

                i {
                    color: $badge-danger;
                    font-size: 1.5em;
                }
            }
        }
    }
}

.accordion {
    .card {
        margin-bottom: rem(30px);
        border: 0;
        border-radius: 0;
    }

    .card-header {
        margin-bottom: 0 !important;
        padding: 0;
        background: transparent;
        border: 1px solid $color-primary;
        border-bottom: 0;

        .btn {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: rem(15px) rem(20px);
            font-weight: $font-weight-bold;
            text-align: left;
            box-shadow: none;

            @include media-breakpoint-up(md) {
                padding: rem(15px) rem(30px);
            }

            @include hover-focus-active() {
                outline: none;
                box-shadow: none;
            }

            &::after {
                z-index: 5;
                flex: 0 0 rem(25px);
                content: "";

                @include svgIcon("minus", rem(25px), rem(25px));
            }

            &.collapsed {
                border-bottom: 1px solid $color-primary;

                &::after {
                    @include svgIcon("plus", rem(25px), rem(25px));
                }
            }
        }
    }

    .card-body {
        padding-bottom: rem(15px);
        padding-left: rem(20px);

        border: 1px solid $color-primary;
        border-top: none;

        @include media-breakpoint-up(md) {
            padding-left: rem(30px);
        }
    }
}

.table-open-wrap {
    margin: 0 -#{rem(10px)};
}

.table-open {
    td {
        padding: 0 rem(10px);

        &:first-child {
            @include media-breakpoint-down(sm) {
                padding-left: 0;
            }
        }
    }
}
