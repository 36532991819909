$backgroundColors: (
        'bg-gray': $gray-500,
        'bg-primary': $primary,
        'bg-secondary': $secondary,
        'bg-success': $success,
        'bg-warning': $warning,
        'bg-danger': $danger
);

$backgroundOpacity: (
        'bg-opacity-10': 0.1,
        'bg-opacity-20': 0.2,
        'bg-opacity-30': 0.3,
        'bg-opacity-40': 0.4,
        'bg-opacity-50': 0.5,
        'bg-opacity-60': 0.6,
        'bg-opacity-70': 0.7,
        'bg-opacity-80': 0.8,
);

@each $bgColorClass, $color in $backgroundColors {
  .#{$bgColorClass} {
    background-color: white !important;
    @each $bgOpacityClass, $opacity in $backgroundOpacity {
      &.#{$bgOpacityClass} {
        background-color: rgba($color, $opacity) !important;
      }
    }
  }
}