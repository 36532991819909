//
// Code
// --------------------------------------------------

:not(pre) > code[class*='language-'],
pre[class*='language-'],
pre {
  margin: 0;
  padding: {
    top: $spacer * 1.5;
    bottom: $spacer * 1.5;
  }
  background-color: $pre-bg;
}
pre {
  padding: {
    right: $spacer;
    left: $spacer;
  }
}
.code-toolbar {
  :not(pre) > code[class*='language-'],
  pre[class*='language-'] {
    padding-top: $spacer * 2;
  }
}
code[class*="language-"],
pre[class*="language-"],
pre {
  color: $pre-color;
}

// Line numbers
.line-numbers .line-numbers-rows {
  border-right: $pre-line-numbers-border-width solid $pre-line-numbers-border-color;
  > span::before {
    color: $pre-line-numbers-color;
  }
}

// Toolbar (Copy button)
div.code-toolbar > .toolbar {
  opacity: 1;
  a {
    display: inline-block;
    margin: .375rem .5rem;
    padding: .25rem .75rem;
    transition: $btn-transition;
    border: $border-width solid $border-color;
    border-radius: 0;
    background-color: theme-color('secondary');
    color: $body-color;
    box-shadow: none;
    &:hover {
      border-color: theme-color('primary');
      background-color: theme-color('primary');
      color: $white;
    }
  }
}
