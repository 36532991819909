//
// project-variables.scss
// Use this to overwrite Bootstrap and CreateX variables
//

// Example of a variable override to change Dashkit's background color
// Remove the "//" to comment it in and see it in action!
// $body-bg: #12263F;

// 1.Grid - použijeme pouze v případě, že se mění rozměry layoutu. Jinak necháváme defaultní.

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1345px
);

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1285px
);

// 2.Colors

$color-white:       #fff;
$color-black:       #000;

$color-primary:     #EC955B;
$color-secondary:   $color-black;

$color-gray:        rgba(40, 40, 40, 0.2);
$color-gray-second: #5e5e5e;
$color-gray-third:  #f0f0f0;
$color-gray-fourth: #989aa2;
$color-gray-fifth:  #868686;

$link-color: $color-black;
$headings-color: $color-primary;
$body-bg: $color-white;
$body-color: $color-black;

// 3.Typography

$font-weight-light:          300;
$font-weight-normal:         400;
$font-weight-bolder:         500;
$font-weight-bold:           700;

$font-family-base:           "Roboto Condensed", sans-serif;
$font-size-base:             1.125rem;
$font-weight-base:           $font-weight-light;
$line-height-base:           1.3;

$h1-font-size: 1.875rem;
$h2-font-size: 1.75rem;
$h3-font-size: 1.5rem;
$h4-font-size: 1.25rem;
$h5-font-size: 1rem;
$h6-font-size: .75rem;
$headings-font-weight: $font-weight-light;
$link-hover-decoration: none;

$navbar-border-width: 0;

// 4.Badges

$badge-primary:             $color-primary;
$badge-secondary:           $color-secondary;
$badge-info:                #2e8098;
$badge-success:             #249700;
$badge-warning:             #b35e00;
$badge-danger:              #E11515;

$theme-colors: (
        "primary": $color-primary,
        "secondary": $color-black,
        "success":    #249700,
        "info":       $badge-info,
        "warning":    $badge-warning,
        "danger":     #E11515,
);

// 5.Buttons

$btn-font-weight:           $font-weight-light;
$btn-font-size:             1rem;
$btn-padding-x:             4rem;
$btn-padding-y:             .813rem;
$btn-border-radius:         0;
$link-hover-color:          $black;
$btn-box-shadow:            none;

// 6.Forms

$input-box-shadow:        none;
$input-border-color:      $color-primary;
$input-border-radius:     0;
$custom-control-indicator-size: 2.625rem;
$custom-checkbox-indicator-border-radius: 0;
$custom-control-indicator-border-color: $color-gray-fourth;
$form-grid-gutter-width: .938rem;

// 7.Img

$img-path:                "../img/";

// 8. Grid

$grid-gutter-width: 15px;
$grid-gutter-width: 15px;

// 9. Modal

$modal-md: 900px;
