// Header

.header {
    &.-sticky {
        position: sticky;

        top: 50px;
        margin-top: -30px;

        @include media-breakpoint-down(md) {
            margin-top: 0;
        }
    }

    .header-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include media-breakpoint-down(sm) {
            padding-left: 1.55rem;
            padding-right: 1.55rem;
        }

        @include media-breakpoint-up(lg) {
            flex-direction: column;
        }
    }

    .navbar {
        position: static;
    }

    .navbar-collapse {
        position: absolute;
        top: calc(100% + 1rem);
        right: 0;
        left: 0;
        //background: $white;
    }

    .navbar-toggler {
        position: relative;
        width: 28px;
        height: 28px;
        top: 3px;

        &:focus {
            outline: none;
        }

        &:not(.collapsed) {
            .line:nth-child(1) {
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -o-transform: rotate(45deg);
                transform: rotate(45deg);
                top: 2px;
                left: 5px;
            }

            .line:nth-child(2) {
                width: 0%;
                opacity: 0;
            }

            .line:nth-child(3) {
                -webkit-transform: rotate(-45deg);
                -moz-transform: rotate(-45deg);
                -o-transform: rotate(-45deg);
                transform: rotate(-45deg);
                top: 22px;
                left: 5px;
            }
        }

        .line {
            display: block;
            position: absolute;
            height: 2px;
            width: 100%;
            background: $color-primary;
            border-radius: 9px;
            opacity: 1;
            left: 0;

            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: .25s ease-in-out;
            -moz-transition: .25s ease-in-out;
            -o-transition: .25s ease-in-out;
            transition: .25s ease-in-out;

            &:nth-child(1) {
                top: 0px;
                -webkit-transform-origin: left center;
                -moz-transform-origin: left center;
                -o-transform-origin: left center;
                transform-origin: left center;
            }

            &:nth-child(2) {
                top: 10px;
                -webkit-transform-origin: left center;
                -moz-transform-origin: left center;
                -o-transform-origin: left center;
                transform-origin: left center;
            }

            &:nth-child(3) {
                top: 20px;
                -webkit-transform-origin: left center;
                -moz-transform-origin: left center;
                -o-transform-origin: left center;
                transform-origin: left center;
            }
        }

        /*&::after {
            content: "";

            @include svgIcon("respo", rem(30px), rem(28px));
        }*/
    }
}

.header-logo {
    max-height: rem(30px);
    overflow: hidden;

    margin-right: 1.5rem;

    position: relative;
    left: -4px;
    top: 1px;

    @include media-breakpoint-up(lg) {
        max-height: 100%;
        margin-bottom: 2.125rem;
    }
}

.header-menu {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style-type: none;

    @include media-breakpoint-down(md) {
        padding-top: 14px;
    }

    .header-menu-item {
        margin-bottom: rem(15px);
    }

    .header-menu-link {
        display: flex;
        align-items: center;
        padding: rem(13px) rem(14px);
        font-size: 1rem;
        text-transform: uppercase;
        text-decoration: none;
        background: $color-gray;
        /*font-weight: 600;*/
        color: #303030;
        line-height: 1;
        transition: color .15s ease-in-out, background-color .15s ease-in-out;

        @include media-breakpoint-down(md) {
            justify-content: center;
            padding: rem(18px) rem(14px);
        }

        &:hover,
        &:focus,
        &.active {
            color: $color-white;
            text-decoration: none;
            background: $color-primary;
        }
    }
}
