//
// Mixins
// --------------------------------------------------

// Scroll styling
@mixin scrollbar($width, $barColor, $barHandle, $barHandleHover) {
  &::-webkit-scrollbar {
    width: $width;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: $barColor;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $barHandle;
    width: $width;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $barHandleHover;
  }
}

// Font smoothing
@mixin font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Appearance
@mixin appearance($value) {
  -webkit-appearance: $value;
  -moz-appearance: $value;
  appearance: $value;
}

// Body font size variants
@mixin font-size-variant($class, $size) {
  #{$class} {
    font-size: $size !important;
  }
}


// Fether icon size
@mixin feather-icon-size($size) {
  width: $size;
  height: $size;
  margin-top: -.0625rem;
}

// Override default Bootstrap List Groups mixin
@mixin list-group-item-variant-custom($state, $background, $color) {
  .list-group-item-#{$state} {
    color: $color;
    background-color: $background;

    &.list-group-item-action {
      @include hover-focus {
        color: darken($color, 10%);
        background-color: $background;
        box-shadow: inset .125rem 0 0 0 darken($background, 25%);
      }

      &.active {
        box-shadow: inset .125rem 0 0 0 darken($background, 25%);
      }
    }
  }
}

// Social buttons hover
@mixin social-btn-hover($color) {
  &:hover {
    border-color: rgba($color, .3);
    color: $color;
  }
}
