//
// Breadcrumb
// --------------------------------------------------

.breadcrumb-item {
  font: {
    size: $breadcrumb-item-font-size;
    weight: $nav-link-font-weight;
  }
  > a {
    transition: $nav-link-transition;
    color: $breadcrumb-item-link-color;
    &:hover {
      color: $breadcrumb-item-link-hover-color;
      text-decoration: none;
    }
  }
  &.active { cursor: default; }
}
.breadcrumb .feather {
  width: ($breadcrumb-item-font-size * .95);
  height: ($breadcrumb-item-font-size * .95);
}