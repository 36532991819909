$floating-label-input-padding-x: .75rem !default;
$floating-label-input-padding-y: .75rem !default;

$floating-label-top-offset: 0 !default;
$floating-label-left-offset: 0 !default;

$floating-label-floating-top-offset-textarea: 0!default;

$floating-label-color: #495057 !default;
$floating-label-line-height: 1.5 !default;
$floating-label-text-area-offset: 5px !default;

$floating-label-floating-color: #777 !default;
$floating-label-floating-size: 12px !default;
$floating-label-floating-top-offset: 0 !default;
$floating-label-floating-left-offset: 0 !default;