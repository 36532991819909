//
// Countdown
// --------------------------------------------------


// Base styles

.countdown {
  display: flex;
  flex-wrap: wrap;
  .countdown-item {
    display: block;
    margin: {
      right: .6em;
      bottom: .4em;
    }
    text-align: center;
    &:last-child { margin-right: 0 !important; }
  }
  .countdown-value,
  .countdown-label {
    display: inline-block;
    vertical-align: baseline;
  }
  .countdown-value {
    color: $countdown-value-color;
  }
  .countdown-label {
    margin-left: .1875rem;
    color: $countdown-label-color;
    font: {
      size: $countdown-label-font-size-sm;
      weight: $countdown-label-font-weight;
    }
  }
  &.h1, &.h2 {
    .countdown-item {
      margin-right: .5em;
    }
    .countdown-label {
      font-size: $countdown-label-font-size-lg;
    }
  }
  &.h3, &.h4 {
    .countdown-item {
      margin-right: .5em;
    }
    .countdown-label {
      font-size: $countdown-label-font-size;
    }
  }
  &.h5, &.h6, &.font-size-lg {
    .countdown-label {
      font-size: $countdown-label-font-size-sm;
    }
  }
}


// Light version

.countdown.text-white {
  .countdown-value {
    color: $countdown-light-value-color;
  }
  .countdown-label {
    color: $countdown-light-label-color;
  }
}
